@tailwind base;
@tailwind components;
@tailwind utilities;

.pac-item {
  @apply flex select-none flex-row justify-between border-none py-2 pl-3 pr-9 font-sans text-sm text-sm text-gray-500 hover:bg-primary-600 hover:text-white;
}

.pac-item:hover .pac-matched {
  @apply text-white;
}

.pac-matched {
  @apply font-semibold text-inherit text-gray-900;
}

.pac-item-query {
  @apply text-sm text-inherit;
}

.pac-icon {
  @apply hidden;
}

.pac-container {
  @apply mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}

.pac-container::after {
  @apply hidden;
}

div:has(> div > div.gmnoprint + div.gmnoprint) {
  opacity: 20% !important;
  display: none;
}
